var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"splash-container"},[_c('div',{staticClass:"card card-border-color card-border-color-success"},[_vm._m(0),_c('div',{staticClass:"card-body"},[(_vm.error)?_c('div',{staticClass:"alert alert-danger"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('form',{attrs:{"method":"post"}},[_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password:")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.user.password),expression:"user.password",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required|min:6|max:50'),expression:"'required|min:6|max:50'"}],ref:"password",staticClass:"form-control",attrs:{"id":"password","type":_vm.passFieldType,"name":"password","placeholder":"Password","required":""},domProps:{"value":(_vm.user.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "password", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"input-group-append showPassDiv"},[_c('i',{class:[
                    'mdi form-control show_password_icon',
                    _vm.showNewPass ? 'mdi-eye-off' : 'mdi-eye',
                  ],on:{"click":_vm.showNewPassword}})])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.first("password")))])]),_c('div',{staticClass:"form-group col-md-12"},[_c('label',{attrs:{"for":"password_confirmation"}},[_vm._v("Confirm Password:")]),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.user.password_confirmation),expression:"user.password_confirmation",modifiers:{"trim":true}},{name:"validate",rawName:"v-validate",value:('required|confirmed:password'),expression:"'required|confirmed:password'"}],staticClass:"form-control",attrs:{"data-vv-as":"password","id":"password_confirmation","type":_vm.newPassType,"name":"password_confirmation","placeholder":"Retype Password","required":""},domProps:{"value":(_vm.user.password_confirmation)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.user, "password_confirmation", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"input-group-append showPassDiv"},[_c('i',{class:[
                    'mdi form-control show_password_icon',
                    _vm.showConfirmPass ? 'mdi-eye-off' : 'mdi-eye',
                  ],on:{"click":_vm.showConfirmPassword}})])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errors.first("password_confirmation")))])]),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-success btn-block",attrs:{"disabled":_vm.submitting,"type":"button"},on:{"click":function($event){return _vm.resetPassword()}}},[_vm._v(" "+_vm._s(_vm.submit)+" ")])]),_c('div',{staticClass:"form-group"},[_c('router-link',{attrs:{"to":{ name: 'login' }}},[_vm._v("Back to Login")])],1)])])]),_c('div',{staticClass:"splash-footer"},[_c('span',[_vm._v("© "+_vm._s(_vm.year)+" "),_c('a',{staticClass:"font-weight-bold ml-1",attrs:{"href":"","target":"_blank"}},[_vm._v("ARED")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('img',{staticClass:"logo-img",attrs:{"src":"/img/brand/logo.png","alt":"Mshiriki","width":"180","height":"70"}}),_c('span',{staticClass:"splash-description font-weight-bold"},[_vm._v("Reset Password.")])])
}]

export { render, staticRenderFns }