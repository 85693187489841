<template>
  <div>
    <div class="splash-container">
      <div class="card card-border-color card-border-color-success">
        <div class="card-header">
          <img
            class="logo-img"
            src="/img/brand/logo.png"
            alt="Mshiriki"
            width="180"
            height="70"
          /><span class="splash-description font-weight-bold"
            >Reset Password.</span
          >
        </div>
        <div class="card-body">
          <div v-if="error" class="alert alert-danger">{{ error }}</div>
          <form method="post">
            <div class="form-group col-md-12">
              <label for="password">Password:</label>
              <div class="input-group">
                <input
                  v-model.trim="user.password"
                  ref="password"
                  v-validate="'required|min:6|max:50'"
                  id="password"
                  :type="passFieldType"
                  name="password"
                  placeholder="Password"
                  class="form-control"
                  required
                />
                <div class="input-group-append showPassDiv">
                  <i
                    :class="[
                      'mdi form-control show_password_icon',
                      showNewPass ? 'mdi-eye-off' : 'mdi-eye',
                    ]"
                    @click="showNewPassword"
                  ></i>
                </div>
              </div>
              <small class="text-danger">{{ errors.first("password") }}</small>
            </div>
            <div class="form-group col-md-12">
              <label for="password_confirmation">Confirm Password:</label>
              <div class="input-group">
                <input
                  data-vv-as="password"
                  v-model.trim="user.password_confirmation"
                  v-validate="'required|confirmed:password'"
                  id="password_confirmation"
                  :type="newPassType"
                  name="password_confirmation"
                  placeholder="Retype Password"
                  class="form-control"
                  required
                />
                <div class="input-group-append showPassDiv">
                  <i
                    :class="[
                      'mdi form-control show_password_icon',
                      showConfirmPass ? 'mdi-eye-off' : 'mdi-eye',
                    ]"
                    @click="showConfirmPassword"
                  ></i>
                </div>
              </div>
              <small class="text-danger">{{
                errors.first("password_confirmation")
              }}</small>
            </div>
            <div class="form-group">
              <button
                :disabled="submitting"
                @click="resetPassword()"
                class="btn btn-success btn-block"
                type="button"
              >
                {{ submit }}
              </button>
            </div>
            <div class="form-group">
              <router-link :to="{ name: 'login' }">Back to Login</router-link>
            </div>
          </form>
        </div>
      </div>
      <div class="splash-footer">
        <span
          >© {{ year }}
          <a href="" class="font-weight-bold ml-1" target="_blank">ARED</a>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import router from "../../router";

export default {
  name: "ResetPasswordForm",
  props: {
    uid: {
      type: String,
      default() {
        return "";
      },
    },
    token: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      submitting: false,
      user: {
        password: "",
        password_confirmation: "",
      },
      error: "",
      submit: "Reset Password",
      year: new Date().getFullYear(),
      showNewPass: false,
      showConfirmPass: false,
      passFieldType: "password",
      newPassType: "password",
    };
  },
  methods: {
    async resetPassword() {
      try {
        this.submit = "Requesting....";

        const data = {
          uid: this.uid,
          token: this.token,
          new_password: this.user.password,
        };
        await axios
          .post(`v1/auth/users/reset_password_confirm/`, data)
          .then((response) => {
            this.submit = "Reset Password";
            if (response.status === 204) {
              router.push({ name: "login" });
              this.$swal("", `Password Reset successful.`, "success");
            }
          });
      } catch (e) {
        this.submit = "Reset Password";

        if (e.response.data.detail) {
          this.$toast.info(e.response.data.detail);
        } else if (
          e.response.data.new_password &&
          e.response.data.new_password.length
        ) {
          this.$swal("", e.response.data.new_password[0], "error");
        } else if (e.response.data.token && e.response.data.token.length) {
          this.$swal(
            "",
            "Password reset link expired. Initiate the process again to receive a new link in your email",
            "error"
          );
        } else {
          this.$swal("", `Could Not reset pass.`, "info");
        }
      }
    },
    showNewPassword() {
      this.showNewPass = !this.showNewPass;
    },
    showConfirmPassword() {
      this.showConfirmPass = !this.showConfirmPass;
    },
  },
  watch: {
    showNewPass() {
      if (this.showNewPass === true) {
        this.passFieldType = "text";
      } else {
        this.passFieldType = "password";
      }
    },
    showConfirmPass() {
      if (this.showConfirmPass === true) {
        this.newPassType = "text";
      } else {
        this.newPassType = "password";
      }
    },
  },
};
</script>
