<template>
  <div>
    <PasswordReset :uid="$route.query.uid" :token="$route.query.token" />
  </div>
</template>
<script>
import PasswordReset from "@/components/Auth/ResetPasswordForm";

export default {
  components: { PasswordReset },
};
</script>
<style></style>
